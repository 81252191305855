<template>
  <div class="LoginPageTheme">
    <!-- color="#76C8F1" -->
    <v-app class="LoginPageTheme">
      <v-row color="grey">
        <v-col class="d-flex align-center justify-center" cols="24" md="12">
          <v-card
            justify="center"
            width="33%"
            elevation="1"
            class="pa-5 mt-0 pt-0 LoginCard"
          >
            <v-img
              class="mx-auto mt-0 pt-0"
              width="30%"
              src="../../assets/images/logo/karakLogo.jpg"
            >
            </v-img>
            <v-col cols="12" class="pa-0 mb-6">
              <v-text-field
                prepend-inner-icon="mdi-account"
                v-model="userName"
                label="Username"
                outlined
                hide-details
                tile
                color="grey"
                class="custom-text-field custom-input border-right"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0 mb-2">
              <v-text-field
                tile
                prepend-inner-icon="mdi-lock"
                :append-icon="show1 ? 'ri-eye-line' : 'ri-eye-close-line'"
                :type="show1 ? 'text' : 'password'"
                hint="At least 8 characters"
                @click:append="show1 = !show1"
                hide-details
                label="Password"
                outlined
                color="grey"
                v-model="password"
                class="custom-text-field"
                @input="count += 1"
                @keyup.enter="signIn"
              ></v-text-field>
              <v-layout>
                <v-spacer></v-spacer>
                <span class="myLoginCount mx-2">{{ count }}</span>
              </v-layout>
            </v-col>
            <v-layout>
              <v-spacer></v-spacer>
              <v-btn
                width="25%"
                dark
                tile
                height="45"
                depressed
                elevation="0"
                class="ThemeColor"
                @click="signIn"
                :disabled="checkValue(userName && password)"
              >
                <!-- :disabled="checkDesable(userName) && checkDesable(password)" -->
                Log In
              </v-btn>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar color="red" top v-model="snackbar" :timeout="timeout">
        {{ snakBarTxt }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-progress-circular
        v-if="loader"
        indeterminate
        size="30"
        width="3"
        color="#3F4775"
        class="myloader2"
      ></v-progress-circular>
    </v-app>
  </div>
</template>
  
  <script>
import config from "../../assets/configuration/config.js";
import axios from "axios";
export default {
  data: () => ({
    count: 0,
    snackbar: false,
    snakBarTxt: "Email or Password incorrect",
    timeout: 2000,
    userName: "",
    password: "",
    loader: false,
    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      emailMatch: () => `The password you entered don't match`,
    },
  }),
  methods: {
    checkValue(value) {
      if (!value) {
        return true;
      } else {
        return false;
      }
    },
    countCharacters() {
      this.count = this.password.length;
    },
    checkDesable(value) {
      if (value === "") {
        return true;
      } else {
        return false;
      }
    },
    signIn() {
      this.loader = true;
      // console.log(this.userName);
      // console.log(this.password);
      axios
        .post(config.BaseUrl + "/api/Auth/authenticate", {
          username: this.userName,
          password: this.password,
          newPassword: "",
          role: 100,
        })
        .then((res) => {
          //   console.log(res)
          this.loader = false;
          if (res.data.role == 100) {
            (this.$store.state.loggedIn = true),
              localStorage.setItem("loggedIn", true);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("LoggedInUser", res.data.userId);
            localStorage.setItem("role", res.data.role);
            localStorage.setItem("username", res.data.username);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.token;
            this.$router.push("/pending");
          } else {
            this.snakBarTxt = "You are Unauthorized!";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.snakBarTxt = "Email Or Password Invalid";
          this.loader = false;
          this.snackbar = true;
        });
    },
    logout() {
      this.$store.state.loggedIn = false;
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("adminInfo");
      localStorage.removeItem("LoggedInUser");
      localStorage.removeItem("role");
    },
  },
  created() {
    this.logout();
  },
  watch: {
    password(newValue) {
      this.count = this.password.length;
      if (newValue === "") {
        this.count = 0;
      }
    },
  },
};
</script>

<style scoped>
.custom-text-field .custom-text-field:active,
.custom-text-field .custom-text-field:focus,
.custom-text-field .custom-text-field:active,
.custom-text-field textarea:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.custom-text-field .v-input__control .v-input__slot {
  border: none !important;
  box-shadow: none !important;
}

.custom-text-field .v-label {
  display: none;
}

.custom-input.v-text-field--outlined.v-input--dense .v-label {
  font-size: 14px !important;
}

.custom-input.v-text-field.v-input--is-focused fieldset {
  border: 1px solid #64718a !important;
}

.custom-input.v-text-area.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid #64718a !important;
}

.custom-input.v-input .v-input__slot {
  border-radius: 0px;
}
.border-right.v-text-field--outlined fieldset {
  border: 1px solid rgb(225, 224, 224) !important;
  border-right: 0px !important;
}
.myloader2 {
  position: absolute;
  top: 50%;
  left: 49.4%;
}

.LoginPageTheme {
  background-color: #00632d !important ;
}
</style>