<template>
  <div>
    <div>
      <v-container>
        <!-- *****************************************************************Heading Section************************************************ -->

        <v-row justify="space-between" class="TopRow py-1">
          <v-col class="d-flex pa-0 align-center justify-space-between" cols="12" sm="8">
            <h3 class="text-uppercase font-weight-medium">pending</h3>
            <v-btn
                class="mx-1 pa-3 ThemeColor addButn"
                dark
                elevation="0"
                height="38"
                link
                tile
                @click="ExportSummons"
                v-if="selectedSummons.length > 0"
            >
              <i class="ri-file-excel-2-line"></i>
              <span class="mx-2 caption">Export</span>
            </v-btn>
          </v-col>
          <v-col class="d-flex pa-0" cols="12" sm="4">
            <v-text-field
                v-model.trim="filteredText"
                color="#3F4775"
                append-icon="mdi-magnify"
                class="darken-2 custom-input"
                clearable
                dense
                hide-details
                outlined
                placeholder="Search"
                @click:append="GetAllSummon()"
                @input="GetAllSummon()"
                @keyup.enter="GetAllSummon()"
            ></v-text-field>
            <v-btn
                class="ms-2 pa-3 ThemeColor addButn"
                dark
                elevation="0"
                height="38"
                link
                tile
                @click="navigateToView(item)"
            >
              <v-icon small>ri-add-circle-fill</v-icon>
              <span class="mx-2 caption"> Add Summon </span>
            </v-btn>
          </v-col>
        </v-row>

        <!-- *****************************************************************Table Section************************************************ -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <template>
              <div>
                <!-- <v-data-table
                  v-if="PendingRecords == null"
                  :headers="headers"
                  class="grey--text elevation-0"
                  hide-default-footer
                ></v-data-table> -->
                <v-data-table
                    v-model="selectedSummons"
                    :headers="headers"
                    item-key="summonId"
                    :items="PendingRecords"
                    class="elevation-0 customTable"
                    id="pendingTable"
                    hide-default-footer
                    @click:row="PendingRecord"
                    disable-pagination
                    show-select
                >
                  <template v-slot:[`item.sno`]="{ index }">
                    <span>{{ (index += 1) }}</span>
                  </template>
                  <template v-slot:[`item.issueDate`]="{ item }">
                    <span>{{ formatDate(item.issueDate) }}</span>
                  </template>
                  <template v-slot:[`item.appearanceDate`]="{ item }">
                    <span>{{ formatDate(item.appearanceDate) }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <router-link
                            :to="{
                            name: 'addsummon',
                            params: { id: item },
                            query: { component: 'pending' },
                          }"
                            class="routerLink mx-2"
                        >
                          <i v-bind="attrs" v-on="on" class="mgc_edit_line"></i>
                        </router-link>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                            id="DeleteIcon"
                            @click="(Deletedialog = true), CurrentRecord(item)"
                            v-bind="attrs"
                            v-on="on"
                            class="mgc_delete_2_line"
                        ></span>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-row class="align-center mt-4 mb-6 me-1">
                  <v-spacer></v-spacer>
                  <span class="caption">Rows per page</span>
                  <div class="mx-2">
                    <v-select
                        :items="options"
                        v-model="selectedPageSize"
                        @change="GetAllSummon"
                        color="#3F4775"
                        dense
                        hide-details
                        class="myselect pa-0 ma-0"
                        :menu-props="{ bottom: true, offsetY: true }"
                    >
                    </v-select>
                  </div>
                  <span class="caption">
                    Page {{ pagination.page }} of
                    {{ pagination.totalPages }}</span
                  >
                  <span>
                    <v-btn
                        color="#3F4775"
                        icon
                        small
                        text
                        @click="backwardButtonCall"
                        class="pa-0"
                    >
                      <v-icon small class="text--indigo"
                      >ri-arrow-left-s-line</v-icon
                      >
                    </v-btn>

                    <v-btn
                        color="#3F4775"
                        icon
                        small
                        text
                        @click="forwardButtonCall()"
                        class="pa-0"
                    >
                      <v-icon small class="text--indigo"
                      >ri-arrow-right-s-line</v-icon
                      >
                    </v-btn>
                  </span>
                </v-row>
              </div>
            </template>
          </v-col>
        </v-row>

        <!-- *****************************************************************Delete Dialog************************************************ -->

        <div class="text-center">
          <v-dialog v-model="Deletedialog" width="1000">
            <v-card>
              <v-card-title dark class="white--text text-h7 ThemeColor">
                Delete Summon
              </v-card-title>

              <v-card-text class="body-1 pa-5">
                Do you want to Delete
                <span class="red--text title">{{ CurrentName }}</span
                >?
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="#F7F8F9"
                    class="ms-2 black--text caption addButn"
                    elevation="0"
                    height="40"
                    link
                    @click="Deletedialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="red"
                    class="ms-2 caption addButn"
                    dark
                    elevation="0"
                    height="40"
                    link
                    @click="(Deletedialog = false), DeleteRecord()"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-row>
          <v-snackbar :color="snackbarColor" top v-model="snackbar" :timeout="5000">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-row>
        <v-progress-circular
            v-if="loader"
            indeterminate
            size="30"
            width="3"
            color="#3F4775"
            class="myloader"
        ></v-progress-circular>
      </v-container>
    </div>

  </div>
</template>

<script>
import config from "../../assets/configuration/config.js";
import axios from "axios";
import moment from "moment";
import download from 'downloadjs';

export default {
  data() {
    return {
      item: 0,
      selectedSummons: [],
      pagination: {},
      snackbar: false,
      snackbarText: "Data Exported Successfully!.",
      snackbarColor: "green",
      filteredText: "",
      selectedPageSize: 25,
      page: 1,
      options: [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100},
      ],
      LoggedInUser: "",
      CurrentName: "",
      text: "Summon Deleted Successfully",
      timeout: 2000,
      loader: false,
      CurrentRecordArr: null,
      Deletedialog: false,
      PendingRecords: [],
      PendingSummon: {
        pageSize: 25,
        page: 1,
        filterText: "",
      },
      selectedRecord: null,
      defSelectedPage: 10,
      Pages: [10, 25, 50, 100],
      selectedPage: 1,
      TotalPages: 10,
      myAPP: [
        {
          summonId: 0,
          caseId: "",
          userId: 0,
          user: {
            userId: 0,
            username: "",
            password: "",
            fullName: "",
            role: 100,
          },
          caseTitle: "",
          court: "",
          judge: "",
          name: "",
          address: "",
          issueDate: "2023-07-27T09:19:31.265Z",
          appearanceDate: "2023-07-27T09:19:31.265Z",
          contactNo: "",
          cnic: "",
          status: 10,
          subject: "",
          remarks: "",
          images: "",
          latitude: "",
          longitude: "",
        },
      ],
      AllRecrodsObj: {
        summonId: 0,
        caseId: "",
        caseTitle: "",
        court: "",
        name: "",
        address: "",
        issueDate: "",
        appearanceDate: "",
        contactNo: "",
        cnic: "",
        judge: "",
        status: 10,
        subject: "",
        remarks: "",
        images: "",
        latitude: "",
        longitude: "",
      },

      headers: [
        {
          text: "Case Id",
          align: "start",
          sortable: false,
          width: "10%",
          value: "caseId",
        },
        {
          text: "Title",
          value: "caseTitle",
          align: "start",
          sortable: false,
          width: "10%",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          width: "10%",
          value: "name",
        },
        {
          text: "Court",
          value: "court",
          align: "start",
          sortable: false,
          width: "23%",
        },
        {
          text: "Issue Date",
          value: "issueDate",
          align: "start",
          sortable: false,
          width: "16%",
        },
        {
          text: "Appearance",
          value: "appearanceDate",
          align: "start",
          sortable: false,
          width: "16%",
        },
        {
          text: "Contact",
          value: "contactNo",
          align: "start",
          sortable: false,
          width: "10%",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          width: "2%",
          sortable: false,
        },
      ],
    };
  },
  // watch: {
  //   "PendingSummon.page"(newValue, oldValue) {
  //     if (newValue !== oldValue) {
  //       this.GetAllSummon();
  //     }
  //   },
  // },
  methods: {
    navigateToView(item) {
      console.log("item to details ---> ", item);
      // Navigate to the details component and pass the selected item
      this.$router.push({
        name: "addsummon",
        params: {id: item},
        query: {component: "pending"},
      });
      // "{ name: 'addsummon', params: { id: item } }"
      // localStorage.setItem("summonId", this.$route.params.id.summonId);
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "";
    },
    GetAllSummon() {
      // console.log(localStorage.getItem("LoggedInUser"))
      this.loader = true;
      axios
          .post(config.BaseUrl + "/api/Summon/GetAll", {
            pageSize: this.selectedPageSize,
            page: this.page,
            filterText: this.filteredText,
            summonStatus: 10,
            userId: localStorage.getItem("LoggedInUser"),
            role: localStorage.getItem("role"),
          })
          .then((res) => {
            this.loader = false;
            this.PendingRecords = res.data.data;
            this.pagination = res.data.pagination;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    forwardButtonCall() {
      if (this.page < this.pagination.totalPages) {
        this.page += 1;
        this.GetAllSummon();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.page > 1) {
        this.page -= 1;
        this.GetAllSummon();
      } else {
        return;
      }
    },

    CurrentRecord(item) {
      // console.log("Current Entity is " + item);
      this.CurrentRecordArr = item;
      this.CurrentName = item.name;
    },

    async DeleteRecord() {
      this.loader = true;
      try {
        let fd = new FormData();
        fd.append("summonId", this.CurrentRecordArr.summonId);
        let response = await axios({
          method: "Post",
          url: config.BaseUrl + "/api/Summon/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.GetAllSummon();
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }
    },

    PendingRecord(item) {
      this.selectedRecord = item;
      localStorage.setItem("selectedPendingRecord", item);
    },
    clearlocalStorage() {
      localStorage.removeItem("selectedPendingRecord");
    },
    async ExportSummons() {
      try {
        const ids = this.selectedSummons.map(obj => obj.summonId);
        this.loader = true;
        this.selectedSummons.forEach(summon =>{
          console.log(summon.images)
          if(summon.images !== null){
            let imagArray = summon.images.split(',')
            imagArray.forEach(img =>{
              download(img)
            })
          }
        });
        let response = await axios.post(config.BaseUrl + '/api/Summon/Export', ids);
        var sampleArr = this.base64ToArrayBuffer(response.data.data);
        this.saveByteArray("Summons Report.xlsx", sampleArr);
        this.text = "Data Exported Successfully!.";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.loader = false;
      } catch (e) {
        this.text = "Something went wrong!";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.loader = false;
        console.log(e)
      }
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      var blob = new Blob([byte], {type: "application/xlsx"});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    },
  },
  created() {
    this.clearlocalStorage();
    this.GetAllSummon();
  },
};
</script>

<style scoped>
.v-data-table-header {
  height: 30px !important;
}
</style>
        