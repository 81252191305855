<template>
  <div>
    <v-app>
      <v-main>
        <v-container fluid>
          <v-container>
            <v-row>
              <v-col>
                <h2>
                  Sorry This component can't be found
                </h2>
                <a href="/">Back To Home</a>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ForbeddentPage',
  components: {
  }
}
</script>
