<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      app
      class="text-center myNavBar"
      width="120"
      clipped
      elevation="0"
      color="#fff"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h7 mt-3 headerListItems">
            SUMMON
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider></v-divider> -->
      <v-list-item class="cotomItem justify-center py-3" link to="/pending">
        <div class="d-flex flex-column">
          <!-- <v-icon class="pb-2">ri-timer-line</v-icon> -->
          <v-img
            style="align-self: center"
            class="mb-3"
            src="../../assets/images/png/pending.png"
            width="50%"
          >
          </v-img>
          <v-list-item-title class="body-2 headerListItems"
            >PENDING</v-list-item-title
          >
        </div>
      </v-list-item>

      <v-list-item class="cotomItem justify-center py-3" link to="/completed">
        <div class="d-flex flex-column">
          <!-- <v-icon class="pb-2">ri-checkbox-circle-line</v-icon> -->
          <v-img
            style="align-self: center"
            class="mb-3"
            src="../../assets/images/png/completed.png"
            width="34%"
          >
          </v-img>
          <v-list-item-title class="body-2 text--uppercase headerListItems"
            >COMPLETED</v-list-item-title
          >
        </div>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item class="cotomItem justify-center py-3" link to="/judges">
        <div class="d-flex flex-column">
          <!-- <v-icon class="pb-2">ri-group-line</v-icon> -->
          <v-img
            style="align-self: center"
            class="mb-2"
            src="../../assets/images/png/judge.png"
            width="50%"
          >
          </v-img>
          <v-list-item-title class="body-2 text--uppercase headerListItems"
            >Judges</v-list-item-title
          >
        </div>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item class="cotomItem justify-center py-3" link to="/users">
        <div class="d-flex flex-column">
          <v-img
            style="align-self: center"
            class="mb-2"
            src="../../assets/images/png/users.png"
            width="50%"
          >
          </v-img>
          <v-list-item-title class="body-2 text--uppercase headerListItems"
            >USERS</v-list-item-title
          >
        </div>
      </v-list-item>

      <v-divider></v-divider>
    </v-navigation-drawer>

    <!-- ******************************************App Bar*************************************************** -->
    <v-app-bar
      app
      height="60"
      clipped-left
      elevation="0"
      color="#3F4775"
      class="borderBtm"
      dark
    >
      <h2>Summon</h2>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div class="d-flex justify-center"></div>

      <v-spacer></v-spacer>
      <div>
        <span v-if="userName"> {{ userName }} </span>
        <span v-else class="text--white"> User </span>
        <v-icon class="display-1 ml-2" color="#fff">mdi-account-circle</v-icon>
        <v-menu bottom offset-y small>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text depressed small class="" v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="">
            <v-list-item @click="ProfileDialog = true">
              <v-list-item-title small class="d-flex justify-center">
                <span class="caption"> Profile </span>
                <span><v-icon small class="mx-1">ri-user-line</v-icon></span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item Link to="/">
              <v-list-item-title small class="d-flex justify-center">
                <span class="caption"> Logout </span>
                <span
                  ><v-icon small class="mx-1"
                    >ri-logout-box-r-line</v-icon
                  ></span
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- *****************************************************************Delete Dialog************************************************ -->

    <div class="text-center">
      <v-dialog width="500" v-model="ProfileDialog">
        <v-card>
          <v-card-title dark class="white--text text-h7 ThemeColor">
            Profile
          </v-card-title>

          <v-card-text class="body-1 pa-5">
            <v-text-field
              tile
              depressed
              dense
              outlined
              label=" Name "
              disabled
              v-model="userName"
            >
            </v-text-field>
            <v-select
              tile
              depressed
              dense
              outlined
              :items="roleArr"
              label="Role"
              v-model="select"
              item-value="value"
              item-text="text"
              return-object
              single-line
              disabled
            >
            </v-select>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ThemeColor caption addButn"
              elevation="0"
              height="40"
              link
              dark
              @click="ProfileDialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userId: "",
      userName: "",
      select: "",
      userRole: {},
      drawer: true,
      ProfileDialog: false,
      roleArr: [
        { text: "Admin", value: "100" },
        { text: "Balaf", value: "200" },
      ],
    };
  },
  methods: {
    pickData() {
      this.userId = localStorage.getItem("LoggedInUser");
      this.userName = localStorage.getItem("username");
      // localStorage.setItem("role",200)
      if (localStorage.getItem("role") == 100)
        this.select = { text: "Admin", value: localStorage.getItem("role") };
      else this.select = { text: "Balaf", value: localStorage.getItem("role") };
    },
  },
  created() {
    this.pickData();
  },
};
</script>

<style>
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 0.9;
}
</style>