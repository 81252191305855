import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginPage from "../components/Navigation/LoginPage.vue"
import PendingPage from "../components/Summon/PendingPage.vue"
import HeaderPage from '../components/Navigation/HeaderPage.vue'
import CompletedPage from '../components/Summon/CompletedPage.vue'
import AddSummon from '../components/Summon/AddSummon.vue'
import imagepage from '../components/Summon/ImagePage.vue'
import ViewSummon from '../components/Summon/ViewSummon.vue'
import UsersPage from '../components/Users/UsersPage.vue'
// import ViewUser from '../components/Users/ViewUser.vue'
import judges from '../components/Judges/JudgesPage.vue'
import AddUser from '../components/Users/AddUser.vue'
import PageNotFound from '../views/ForbeddenPage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  
  {
    path: '/pending',
    name: 'pending',
    component: PendingPage
  },
  // {
  //   path: '/HomePage',
  //   name: 'Hompeage',
  //   component: HomePage
  // },
  {
    path: '/header',
    name: 'header',
    component: HeaderPage
  },
  {
    path: '/completed',
    name: 'completed',
    component: CompletedPage
  },

  {
    path: '/addsummon',
    name: 'addsummon',
    component: AddSummon
  },
  {
    path: '/imagepage',
    name: 'imagepage',
    component: imagepage
  },
  {
    path: '/viewsummon',
    name: 'viewsummon',
    component: ViewSummon
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage
  },
  {
    path: '/judges',
    name: 'judges',
    component: judges
  },
  {
    path: '/adduser',
    name: 'adduser',
    component: AddUser
  },
  // {
  //   path: '/ViewUser',
  //   name: 'ViewUser',
  //   component: ViewUser
  // },
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: "*/",
    name: 'redirectPageNotFound',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
