<template>
  <div>
    <div>
      <v-card elevation="0" class="mySheet">
        <v-card-text color="" class="myCardText pa-0 py-1 px-6 ma-0">
          <v-layout class="marginLayout">
            <v-btn
              class="ThemeColor"
              dark
              elevation="0"
              height="35"
              link
              depressed
              to="/pending"
              tile
            >
              <v-icon small> ri-arrow-go-back-line </v-icon>
            </v-btn>

            <div>
              <h2 class="text-uppercase mx-3">
                Edit Summon / Notice / Warrant
              </h2>
            </div>

            <v-spacer></v-spacer>

            <v-btn
              class="ThemeColor ma-1 px-10"
              dark
              elevation="0"
              height="35"
              depressed
              tile
              :disabled="
                checkValue(
                  AddSummonObj.court &&
                    AddSummonObj.name &&
                    AddSummonObj.caseTitle
                )
              "
              @click="UpdateSummon()"
            >
              update
            </v-btn>
          </v-layout>
        </v-card-text>
        <!-- <v-card-title height="10" class="indigo--text pa-0 ma-0 myCardText">
          <h3 class="display-5 ms-8">Please enter the details</h3>
        </v-card-title> -->
        <v-container class="pa-3">
          <v-row class="">
            <v-col cols="8" class="pa-8">
              <v-row class="px-3">
                <v-col cols="6" class="pa-0">
                  <v-text-field
                    outlined
                    tile
                    dense
                    :rules="rules.required"
                    v-model="AddSummonObj.caseTitle"
                    label="Case Title"
                    color="#3F4775"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    :rules="rules.required"
                    v-model="AddSummonObj.name"
                    label="Name"
                    color="#3F4775"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6" class="pa-0">
                  <v-menu
                    v-model="date1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        label="Issue Date"
                        readonly
                        v-bind="attrs"
                        :value="computedIssueDate"
                        v-on="on"
                        @click:clear="date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      @change="date1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Contact"
                    v-model="AddSummonObj.contactNo"
                    color="#3F4775"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="AddSummonObj.subject"
                    outlined
                    dense
                    label="Select Subject"
                    color="#3F4775"
                    :items="SubjectItems"
                    item-text="text"
                    item-value="text"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="AddSummonObj.userId"
                    outlined
                    dense
                    label="Assigned To"
                    color="#3F4775"
                    :items="assignedToArr"
                    item-text="fullName"
                    item-value="userId"
                    :return-object="false"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="AddSummonObj.court"
                    outlined
                    dense
                    label="Select Court"
                    color="#3F4775"
                    :items="CourtItems"
                    item-text="text"
                    item-value="text"
                    :return-object="false"
                  >
                  </v-select>
                </v-col>

                <!-- <v-col cols="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    value="AddSummonObj.subject"
                    v-model="AddSummonObj.subject"
                    outlined
                    dense
                    label="Select Subject"
                    color="#3F4775"
                    :items="SubjectItems"
                    item-text="text"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    value="AddSummonObj.subject"
                    v-model="AddSummonObj.assignedTo"
                    outlined
                    dense
                    label="Assigned To"
                    color="#3F4775"
                    :items="AssignItems"
                    item-text="text"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="AddSummonObj.court"
                    outlined
                    dense
                    label="Select Court"
                    color="#3F4775"
                    :items="CourtItems"
                    item-text="text"
                  >
                  </v-select>

                </v-col> -->
                <v-col cols="6" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Address"
                    v-model="AddSummonObj.address"
                    color="#3F4775"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="pa-5 myCardText">
              <v-menu
                v-model="appearVar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="#3F4775"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    color="#3F4775"
                    :value="computedAppearDate"
                    label="Apearance Date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="appearVar = false"
                  color="#3F4775"
                  v-model="date"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                outlined
                dense
                label="CNIC No"
                v-model="AddSummonObj.cnic"
                return-masked-value
                v-mask="'#####-#######-#'"
                color="#3F4775"
              ></v-text-field>

              <v-text-field
                outlined
                v-model="AddSummonObj.assignedBy"
                dense
                label="Assigned by"
                color="#3F4775"
              ></v-text-field>

              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="AddSummonObj.status"
                outlined
                dense
                label="Select Status"
                color="#3F4775"
                :items="statusItem"
                item-text="text"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row> </v-row>
          <v-row>
            <v-snackbar
              color="success"
              top
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-row>
          <v-progress-circular
            v-if="loader"
            indeterminate
            size="50"
            width="4"
            color="#3F4775"
            class="myloader"
          ></v-progress-circular>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
  
  <script>
import config from "../../assets/configuration/config.js";
import axios from "axios";
import Vue from "vue";
import moment from "moment";
import { format, parseISO } from "date-fns";

import VueMask from "vue-the-mask";
Vue.use(VueMask);
Vue.prototype.moment = moment;
export default {
  data() {
    return {
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      assignedToArr: [],
      date1: false,
      rules: {
        required: [(value) => !!value || "Required."],
      },
      snackbar: false,
      text: "Summon Added Successfully",
      timeout: 2000,
      loader: false,
      issueVar: false,
      date2: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      appearVar: false,
      // issueDate: new Date(),
      // AppearDate: new Date(),
      statusItem: [
        { text: "Pending", value: 10 },
        { text: "Acknowledgeed", value: 20 },
        { text: "Unacknowledgeed", value: 30 },
        { text: "Refused", value: 40 },
        { text: "Completed", value: 50 },
      ],
      myselectedStatus: 10,
      SubjectItems: [
        { text: "Car", value: 1 },
        { text: "Hotel", value: 2 },
        { text: "Room", value: 3 },
        { text: "Road", value: 4 },
        { text: "Hospital", value: 5 },
      ],
      myselectedSub: 1,
      CourtItems: [
        { text: "Karak", value: 11 },
        { text: "Peshawer", value: 22 },
        { text: "Islamabad", value: 33 },
        { text: "Kohat", value: 44 },
        { text: "Banu", value: 55 },
      ],
      myselectedcourt: 11,
      AssignItems: [
        { text: "Jan aalam", value: 2 },
        { text: "Aseel Khan", value: 4 },
        { text: "Sultan Jan", value: 6 },
        { text: "Sami Ullah Jan", value: 8 },
        { text: "Ahmad Khan", value: 10 },
      ],
      myselectedAssign: 2,
      // AddSummonObj: {
      //   summonId: 0,
      //   caseId: "",
      //   caseTitle: "",
      //   court: {
      //     text: "Karak",
      //     value: 11,
      //   },
      //   name: "",
      //   address: "",
      //   issueDate: this.formatDate(new Date()),
      //   appearanceDate: this.formatDate(new Date()),
      //   contactNo: "",
      //   cnic: "",
      //   assignedTo: {
      //     text: "Jan aalm",
      //     value: 2,
      //   },
      //   status: {
      //     text: "Pending",
      //     value: 10,
      //   },
      //   subject: {
      //     text: "Car",
      //     value: 1,
      //   },
      //   remarks: "",
      //   images: "",
      //   latitude: "",
      //   longitude: "",
      // },
      AddSummonObj: {
        summonId: 0,
        caseId: "",
        caseTitle: "",
        court: "",
        name: "",
        address: "",
        issueDate: this.date2,
        appearanceDate: this.date,
        contactNo: "",
        cnic: "",
        assignedTo: "",
        status: 10,
        subject: "",
        remarks: "",
        images: "",
        latitude: "",
        longitude: "",
      },
    };
  },
  computed: {
    computedIssueDate() {
      return this.date2 ? moment(this.date2).format("DD MMM YYYY") : "";
    },
    computedAppearDate() {
      return this.date ? moment(this.date).format("DD MMM YYYY") : "";
    },
  },
  methods: {
    async GetUsers() {
      // this.loader = true;
      try {
        axios
          .post(config.BaseUrl + "/api/User/All", {
            pageSize: this.mypageSize,
            page: this.mypage,
            filterText: "",
          })
          .then((res) => {
            console.log("braught a response");
            console.log(res.data.data);
            this.assignedToArr = res.data.data;
            // this.assignedToArr.forEach((elevation) => {
            //   console.log(elevation.fullName);
            // });
          });
      } catch (err) {
        console.log("directly jumped to error");
        console.log(err);
      }
    },
    getCurrentSummon() {
      this.AddSummonObj = this.$route.params.id;
      const summon_id = this.$route.params.id.summonId;
      // console.log(summon_id)
      var formData = new FormData();
      formData.append("summonId", summon_id);
      axios
        .post(config.BaseUrl + "/API/Summon/Get", formData)
        .then((res) => {
          this.AddSummonObj = res.data.data;
          console.log("this is the data" + this.AddSummonObj);
        })
        .catch((Error) => {
          console.log(Error);
        });
    },

    async UpdateSummon() {
      this.loader = true;
      try {
        await axios
          .post(config.BaseUrl + "/api/Summon/AddOrUpdate", this.AddSummonObj)
          .then(() => {
            console.log("Record Updated");
            this.text = "Summon Updated Successfully";
            this.snackbar = true;
            this.loader = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    // logCourt() {
    //   console.log(this.AddSummonObj.court);
    // },

    checkValue(value) {
      if (!value) {
        return true;
      } else {
        return false;
      }
    },
    // async AddSummonRecord() {
    //   console.log(this.AddSummonObj.status);
    //   this.loader = true;
    //   try {
    //     await axios
    //       .post(config.BaseUrl + "/api/Summon/AddOrUpdate", this.AddSummonObj)
    //       .then(() => {
    //         console.log("Record Added");
    //         this.snackbar = true;
    //         this.loader = false;
    //       });
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },

    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "";
    },
  },
  created() {
    this.getCurrentSummon();
    this.GetUsers();
  
  },
  // watch:{
  //   if (disabled) {:
  // }
};
</script>

<style>
.v-application--is-ltr .v-text-field .v-label {
  transform-origin: top left !important;
}
.v-text-field > .v-input__control > .v-input__slot .v-text-field__slot {
  outline: none !important;

  border: none !important;
}
.v-application .primary--text {
  color: none !important;
  caret-color: none !important;
}
</style>