<template>
  <div>
    <div>
      <v-container>
        <!-- *****************************************************************Heading Section************************************************ -->

        <v-row justify="space-between" class="TopRow">
          <v-col class="d-flex pa-0" cols="12" sm="6">
            <h3 class="text-uppercase font-weight-medium">Users</h3>
          </v-col>
          <v-col class="mb-1 d-flex pa-0" cols="12" sm="4">
            <v-text-field
              v-model="myFilter"
              color="#3F4775"
              append-icon="mdi-magnify"
              class="darken-2 custom-input"
              clearable
              dense
              hide-details
              outlined
              placeholder="Search"
              @input="GetAllUsers()"
              @click:append="GetAllUsers()"
              @keyup.enter="GetAllUsers()"
            ></v-text-field>

            <v-btn
              class="ms-2 ThemeColor addButn"
              dark
              elevation="0"
              height="38"
              link
              tile
              @click="(AddOrUpdateUser = true), resetDialog()"
            >
              <v-icon small>ri-add-circle-fill</v-icon>
              <span class="mx-2 caption"> Add User </span>
            </v-btn>
          </v-col>
        </v-row>

        <!-- *****************************************************************Table Section************************************************ -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <template>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="UsersRecords"
                  class="elevation-0 customTable"
                  id="pendingTable"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`item.sno`]="{ index }">
                    <span>{{ (index += 1) }}</span>
                  </template>
                  <!-- <template v-slot:[`item.issueDate`]="{ item }">
                    <span>{{ formatDate(item.issueDate) }}</span>
                  </template> -->
                  <template v-slot:[`item.role`]="{ item }">
                    <span v-if="item.role == 100"
                      ><v-chip dark color="green" class="chipClass" x-small
                        >Admin</v-chip
                      ></span
                    >
                    <span v-if="item.role == 200"
                      ><v-chip dark color="blue" class="chipClass" x-small
                        >Balaf</v-chip
                      ></span
                    >
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mx-1 success--text"
                          v-if="item"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="(Editdialog = true), CurrentRecord(item)"
                        >
                          ri-edit-box-line
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip> -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="(AddOrUpdateUser = true), CurrentRecord(item)"
                          class="mx-2 mgc_edit_line"
                        ></span>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="(Deletedialog = true), CurrentRecord(item)"
                          class="mgc_delete_2_line"
                        ></span>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <v-divider></v-divider>
                <v-row class="align-center mt-4 mb-6 me-1">
                  <v-spacer></v-spacer>
                  <span class="caption">Rows per page</span>
                  <div class="mx-2">
                    <v-select
                      :items="optionspaging"
                      v-model="selectedPageSize"
                      @change="GetAllUsers"
                      color="#3F4775"
                      dense
                      hide-details
                      class="myselect pa-0 ma-0"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                    </v-select>
                  </div>
                  <span class="caption">
                    Page {{ pagination.page }} of
                    {{ pagination.totalPages }}</span
                  >
                  <span>
                    <v-btn
                      color="#3F4775"
                      icon
                      small
                      text
                      @click="backwardButtonCall"
                    >
                      <v-icon small class="text--indigo"
                        >ri-arrow-left-s-line</v-icon
                      >
                    </v-btn>

                    <v-btn
                      color="#3F4775"
                      icon
                      small
                      text
                      @click="forwardButtonCall()"
                    >
                      <v-icon small class="text--indigo"
                        >ri-arrow-right-s-line</v-icon
                      >
                    </v-btn>
                    <!-- <v-icon  small class="text--indigo">ri-arrow-right-s-line</v-icon> -->
                  </span>
                </v-row>
              </div>
            </template>
          </v-col>
        </v-row>

        <!-- *****************************************************************Add User Dialog************************************************ -->

        <div class="text-center">
          <v-dialog v-model="AddOrUpdateUser" width="500">
            <v-card>
              <v-card-title dark class="white--text text-h7 ThemeColor">
                {{ DialogTitle }}
              </v-card-title>

              <v-card-text class="body-1 pa-5">
                <!-- Do you want to Delete <span class="red--text title">Name</span>? -->
                <v-text-field
                  outlined
                  dense
                  label="Name"
                  color="#3F4775"
                  v-model="CurrentUser.fullName"
                ></v-text-field>

                <v-text-field
                  outlined
                  dense
                  v-model="CurrentUser.username"
                  label="Username"
                  color="#3F4775"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Password"
                  v-model="CurrentUser.password"
                  color="#3F4775"
                ></v-text-field>

                <v-select
                  :items="options"
                  dense
                  hide-details
                  outlined
                  label="Role"
                  v-model="CurrentUser.role"
                  color="#3F4775"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-value="value"
                  :return-object="false"
                >
                </v-select>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#F7F8F9"
                  class="ms-2 black--text caption addButn"
                  elevation="0"
                  height="38"
                  link
                  @click="AddOrUpdateUser = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="ms-2 caption ThemeColor addButn my-disabled-btn"
                  dark
                  elevation="0"
                  height="38"
                  link
                  @click="(AddOrUpdateUser = false), AddUser()"
                  :disabled="
                    checkValue(CurrentUser.fullName && CurrentUser.username)
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <!--                   :color="getClassName() ? 'blue' : 'grey'"
 -->
        <!-- *****************************************************************Delete Dialog************************************************ -->

        <div class="text-center">
          <v-dialog v-model="Deletedialog" width="500">
            <v-card>
              <v-card-title dark class="white--text text-h7 ThemeColor">
                Delete User
              </v-card-title>

              <v-card-text class="body-1 pa-5">
                Do you want to Delete
                <span class="red--text title">{{ CurrentName }}</span
                >?
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#F7F8F9"
                  class="ms-2 black--text caption addButn"
                  elevation="0"
                  height="38"
                  link
                  @click="Deletedialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="ms-2 ThemeColor addButn"
                  dark
                  elevation="0"
                  height="38"
                  link
                  @click="(Deletedialog = false), DeleteRecord()"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-row>
          <v-snackbar color="success" top v-model="snackbar" :timeout="timeout">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-row>
        <v-progress-circular
          v-if="loader"
          indeterminate
          size="30"
          width="3"
          color="#3F4775"
          class="myloader"
        ></v-progress-circular>
      </v-container>
    </div>
  </div>
</template>

<script>
import config from "../../assets/configuration/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      DialogTitle: "",
      options: [
        { text: "Admin", value: 100 },
        { text: "Balaf", value: 200 },
      ],
      SelectedRole: 100,
      UserObjModel: {
        userId: 0,
        username: "",
        password: "",
        fullName: "",
        role: 100,
      },
      AddOrUpdateUser: false,
      pagination: {},
      selectedPageSize: 25,
      page: 1,
      optionspaging: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      Editdialog: false,
      CurrentName: "",
      CurrentUser: {},
      snackbar: false,
      text: "",
      mycolor: "",
      timeout: 2000,
      loader: false,
      CurrentRecordArr: "",
      Deletedialog: false,
      UsersRecords: [],

      selectedRecord: "",
      AllUsers: {
        pageSize: 25,
        page: 1,
        filterText: "saeed",
      },
      defSelectedPage: 10,
      Pages: [10, 25, 50, 100],
      selectedPage: 1,
      TotalPages: 10,
      mypageSize: 25,
      mypage: 1,
      myFilter: "",
      UsersObjModel: {},
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "sno",
          width: "1%",
        },
        {
          text: "Name",
          value: "fullName",
          align: "center",
          sortable: false,
        },
        {
          text: "Role",
          value: "role",
          align: "center",
          sortable: false,
        },
        {
          text: "Password",
          value: "password",
          align: "start",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          width: "5%",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getClassName() {
      if (this.CurrentUser.fullName && this.CurrentUser.username) {
        return true;
      } else {
        return false;
      }
    },
    checkValue(value) {
      if (!value) {
        return true;
      } else {
        return false;
      }
    },
    resetDialog() {
      this.DialogTitle = "Add new user";
      this.CurrentUser = {
        userId: 0,
        username: "",
        password: "",
        fullName: "",
        role: 100,
      };
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "";
    },
    async AddUser() {
      this.loader = true;
      try {
        await axios
          .post(config.BaseUrl + "/api/User/AddOrUpdate", this.CurrentUser)
          .then(() => {
            console.log("Record Added");
            if (this.CurrentUser.userId == 0) {
              this.text = "User Added successfully";
            } else this.text = "User Updated successfully";
            this.snackbar = true;
            this.loader = false;
            this.GetAllUsers();
          });
      } catch (err) {
        console.error(err);
      }
    },
    async GetAllUsers() {
      this.loader = true;
      try {
        axios
          .post(config.BaseUrl + "/api/User/All", {
            pageSize: this.mypageSize,
            page: this.mypage,
            filterText: this.myFilter,
          })
          .then((res) => {
            // console.log("braught a response")
            // console.log(res);
            this.loader = false;
            this.UsersRecords = res.data.data;
            this.pagination = res.data.pagination;
          });
      } catch (err) {
        console.log("directly jumped to error");
        console.log(err);
      }
    },

    forwardButtonCall() {
      if (this.page < this.pagination.totalPages) {
        this.page += 1;
        this.GetAllUsers();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.page > 1) {
        this.page -= 1;
        this.GetAllUsers();
      } else {
        return;
      }
    },

    async DeleteRecord() {
      this.loader = true;
      try {
        let fd = new FormData();
        // console.log(this.CurrentUser.userId)
        fd.append("userId", this.CurrentUser.userId);
        let response = await axios({
          method: "Post",
          url: config.BaseUrl + "/api/User/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.text = "User Deleted successfully";
        this.snackbar = true;
        this.GetAllUsers();
      } catch (e) {
        console.log(e);
      }
    },

    CurrentRecord(item) {
      // console.log("Current Entity is " + item);
      this.CurrentUser = item;

      this.DialogTitle = "Update";
      // this.CurrentName = item.fullName;
    },

    // CurrentUsersRecord(item) {
    //   this.selectedRecord = item;
    //   localStorage.setItem("selectedCurrentUsersRecord", item);
    // },
    // clearlocalStorage() {
    //   localStorage.removeItem("selectedCurrentUsersRecord");
    // },
  },
  created() {
    // this.clearlocalStorage();
    this.GetAllUsers();
  },
};
</script>

<style scoped>
.v-data-table-header {
  height: 30px !important;
}
</style>
        