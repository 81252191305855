import Vue from "vue"
import "./plugins/axios"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import store from "./store"
import router from "./router"
import axios from "axios";
import "@/assets/CSS/MingCute-main/fonts/MingCute.css";

import config from "@/assets/configuration/config.js";
// store.state.loggedIn = localStorage.getItem("loggedIn");
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

store.state.loggedIn = localStorage.getItem("loggedIn");
//  console.log(localStorage.getItem("loggedIn"));
 
axios.defaults.baseURL = config.BaseUrl;
// console.log(config.BaseUrl);

// axios.defaults.baseURL = config.apiBaseUrl;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

Vue.config.productionTip = false
// var token = localStorage.getItem("token")
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
