<template>
  <div>
    <div>
      <v-container>
        <!-- *****************************************************************Heading Section************************************************ -->

        <v-row justify="space-between" class="TopRow">
          <v-col class="d-flex" cols="12" sm="6">
            <h2 class="text-uppercase">Add New User</h2>
          </v-col>
          <v-col class="mb-1 d-flex" cols="12" sm="3">
            <v-text-field
              append-icon="mdi-magnify"
              class=""
              clearable
              dense
              hide-details
              outlined
              placeholder="Search"
              color="#3F4775"
            ></v-text-field>
            
            <v-btn dark depressed elevation="0" class="mx-4 ThemeColor" height="42" @click="AddUser">
              Add User
            </v-btn>
          </v-col>
          
        </v-row>

        <v-row>
          <v-col cols="4">
              <v-sheet elevation="0" class="pa-5">
                <v-text-field
                  outlined
                  dense
                  label="Name"
                  color="#3F4775"
                  v-model="UserObjModel.fullName"
                ></v-text-field>

                <v-text-field
                  outlined
                  dense
                  v-model="UserObjModel.username"
                  label="Username"
                  color="#3F4775"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Password"
                  v-model="UserObjModel.password"
                  color="#3F4775"
                ></v-text-field>

                <v-select
                :items="options"
                dense
                hide-details
                outlined
                label="Role"
                v-model="UserObjModel.role"
                color="#3F4775"
                :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select>
                <!-- <v-text-field
                  outlined
                  dense
                  label="Role"
                  v-model="UserObjModel.role"
                  color="#3F4775"
                ></v-text-field> -->
              </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12">
            <v-btn class="mr-4" @click="AddUser"> Add User </v-btn>
            <v-btn> clear </v-btn>
          </v-col> -->
        </v-row>


        <v-row>
          <v-snackbar color="success" top v-model="snackbar" :timeout="timeout">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-row>
        <v-progress-circular
          v-if="loader"
          indeterminate
          size="50"
          width="4"
          color="#3F4775"
          class="myloader"
        ></v-progress-circular>
      </v-container>
    </div>
  </div>
</template>
  
  <script>
import config from "../../assets/configuration/config.js";

import axios from "axios";
export default {
  data() {
    return {
      options: [
        { text: "Admin", value: 100 },
        { text: "Balaf", value: 200 },
      ],
      SelectedRole: 100,
      snackbar: false,
      text: "User Added Successfully",
      timeout: 2000,
      loader: false,
      UserObjModel: {
        userId: 0,
        username: "",
        password: "",
        fullName: "",
        role: 100,
      },
      name: "",
      email: "",
    }
  },
  methods:{
        async AddUser() {
      this.loader = true;
      try {
        await axios
          .post(config.BaseUrl + "/api/User/AddOrUpdate", this.UserObjModel)
          .then(() => {
            console.log("Record Added");
            this.snackbar = true;
            this.loader = false;
          });
      } catch (err) {
        console.error(err);
      }
      }
    }

};
</script>