<template>
  <div>
    <div>
      <v-container>
        <!-- *****************************************************************Heading Section************************************************ -->

        <v-row justify="space-between" class="TopRow py-1">
          <v-col class="d-flex pa-0 align-center justify-space-between" cols="12" sm="7">
            <h3 class="text-uppercase font-weight-medium">pending</h3>
            <v-btn
                class="mx-1 pa-3 ThemeColor addButn"
                dark
                elevation="0"
                height="38"
                link
                tile
                @click="ExportSummons"
                v-if="selectedSummons.length > 0"
            >
              <i class="ri-file-excel-2-line"></i>
              <span class="mx-2 caption">Export</span>
            </v-btn>
          </v-col>

          <v-col class="d-flex pa-0" cols="12" sm="2">
            <v-select
              :items="options"
              v-model="SelectsummonStatus"
              @change="GetAllSummon()"
              color="#3F4775"
              dense
              hide-details
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              class="me-md-2"
            >
            </v-select>
          </v-col>
          <v-col class="d-flex pa-0" cols="12" sm="3">
            <v-text-field
              v-model.trim="filteredText"
              color="#3F4775"
              append-icon="mdi-magnify"
              class="darken-2 custom-input"
              clearable
              dense
              hide-details
              outlined
              placeholder="Search"
              @click:append="GetAllSummon()"
              @input="GetAllSummon()"
              @keyup.enter="GetAllSummon()"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- *****************************************************************Table Section************************************************ -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <template>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="PendingRecords"
                  class="elevation-0 customTable"
                  id="pendingTable"
                  hide-default-footer
                  @click:row="PendingRecord"
                  disable-pagination
                  v-model="selectedSummons"
                  item-key="summonId"
                  show-select
                >
                  <template v-slot:[`item.sno`]="{ index }">
                    <span>{{ (index += 1) }}</span>
                  </template>
                  <template v-slot:[`item.issueDate`]="{ item }">
                    <span>{{ formatDate(item.issueDate) }}</span>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <span v-if="item.status == 10">
                      <v-chip x-small class="MychipPen"
                        ><span class="chipsText">Pending</span></v-chip
                      >
                    </span>
                    <span v-else-if="item.status == 20">
                      <v-chip x-small class="MychipAck"
                        >Acknowledged</v-chip
                      ></span
                    >
                    <span v-else-if="item.status == 30">
                      <v-chip x-small class="MychipUnAck"
                        >Unacknowledged</v-chip
                      ></span
                    >
                    <span v-else-if="item.status == 40">
                      <v-chip x-small class="MychipRef">Refused</v-chip></span
                    >
                    <span v-else-if="item.status == 50">
                      <v-chip x-small class="MychipCom">Completed</v-chip></span
                    >
                  </template>
                  <template v-slot:[`item.appearanceDate`]="{ item }">
                    <span>{{ formatDate(item.appearanceDate) }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <router-link
                          :to="{ name: 'imagepage', params: { id: item } }"
                          class="routerLink"
                        >
                          <i
                            v-bind="attrs"
                            v-on="on"
                            class="mgc_eye_2_line"
                          ></i>
                        </router-link>
                      </template>
                      <span>View</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <router-link
                          :to="{
                            name: 'addsummon',
                            params: { id: item },
                            query: { component: 'completed' },
                          }"
                          class="routerLink mx-2"
                        >
                          <i v-bind="attrs" v-on="on" class="mgc_edit_line"></i>
                        </router-link>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          id="DeleteIcon"
                          @click="(Deletedialog = true), CurrentRecord(item)"
                          v-bind="attrs"
                          v-on="on"
                          class="mgc_delete_2_line"
                        ></span>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <v-divider></v-divider>
                <v-row class="align-center mt-4 mb-6 me-1">
                  <v-spacer></v-spacer>
                  <span class="caption">Rows per page</span>
                  <div class="mx-2">
                    <v-select
                      :items="optionspaging"
                      v-model="selectedPageSize"
                      @change="GetAllSummon"
                      color="#3F4775"
                      dense
                      hide-details
                      class="myselect pa-0 ma-0"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                    </v-select>
                  </div>
                  <span class="caption">
                    Page {{ pagination.page }} of
                    {{ pagination.totalPages }}</span
                  >
                  <span>
                    <v-btn
                      color="#3F4775"
                      icon
                      small
                      text
                      @click="backwardButtonCall"
                    >
                      <v-icon small class="text--indigo"
                        >ri-arrow-left-s-line</v-icon
                      >
                    </v-btn>

                    <v-btn
                      color="#3F4775"
                      icon
                      small
                      text
                      @click="forwardButtonCall()"
                    >
                      <v-icon small class="text--indigo"
                        >ri-arrow-right-s-line</v-icon
                      >
                    </v-btn>
                   
                    
                  </span>
                </v-row>
              </div>
            </template>
          </v-col>
        </v-row>

        <!-- *****************************************************************Delete Dialog************************************************ -->

        <div class="text-center">
          <v-dialog v-model="Deletedialog" width="1000">
            <v-card>
              <v-card-title dark class="white--text text-h7 ThemeColor">
                Delete
              </v-card-title>

              <v-card-text class="body-1 pa-5">
                Do you want to Delete
                <span class="red--text title">{{ this.deleterecordName }}</span
                >?
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#F7F8F9"
                  class="ms-2 black--text caption addButn"
                  elevation="0"
                  height="40"
                  link
                  @click="Deletedialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="red"
                  class="ms-2 caption addButn"
                  dark
                  elevation="0"
                  height="40"
                  link
                  @click="(Deletedialog = false), DeleteRecord()"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <!-- *****************************************************************View Summon Dialog************************************************ -->

        <template>
          <v-row justify="center">
            <v-dialog
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-toolbar dark color="primary">
                <v-btn icon dark>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Settings</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text> Save </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-list three-line subheader>
                <v-subheader>User Controls</v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Content filtering</v-list-item-title>
                    <v-list-item-subtitle
                      >Set the content filtering level to restrict apps that can
                      be downloaded</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Password</v-list-item-title>
                    <v-list-item-subtitle
                      >Require password for purchase or use password to restrict
                      purchase</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list three-line subheader>
                <v-subheader>General</v-subheader>
                <v-list-item>
                  <v-list-item-action>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Notifications</v-list-item-title>
                    <v-list-item-subtitle
                      >Notify me about updates to apps or games that I
                      downloaded</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Sound</v-list-item-title>
                    <v-list-item-subtitle
                      >Auto-update apps at any time. Data charges may
                      apply</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
            
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Auto-add widgets</v-list-item-title>
                    <v-list-item-subtitle
                      >Automatically add home screen
                      widgets</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-dialog>
          </v-row>
        </template>

        <template>
          <v-row justify="center">
            <v-dialog
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              v-model="Viewdialog"
            >
              <v-card
                v-if="
                  ViewUser == null || ViewUser == undefined || ViewUser == ''
                "
                class="pa-5"
                outlined
              >
                <v-list-item-content>
                  <h2 class="title mb-4">Summon Details</h2>
                  <v-list-item-title class="text-h5 mb-1">
                    Loading...
                  </v-list-item-title>
                </v-list-item-content>
              </v-card>

              <v-card v-else class="mx-auto" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <h2 class="title">Summon Details</h2>
                    <v-list-item-subtitle>
                      Case Title:
                      <span class="subtitle-2">{{ ViewUser.caseTitle }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Judge:
                      <span class="subtitle-2">{{ ViewUser.judge }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Address:
                      <span class="subtitle-2">{{ ViewUser.address }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Subject:
                      <span class="subtitle-2">{{ ViewUser.subject }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Remarks:
                      <span class="subtitle-2">{{ ViewUser.remarks }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar size="180" v-if="ViewUser.image">
                    <v-img class="pa-2" :src="ViewUser.image"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else tile size="140" color="grey">
                    <span justify="center">Image not Found</span>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    class="ms-2 caption addButn ThemeColor"
                    dark
                    elevation="0"
                    height="40"
                    @click="Viewdialog = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>

        <!-- *****************************************************************Progress and Loader Section************************************************ -->

        <v-row>
          <v-snackbar :color="snackbarColor" top v-model="snackbar" :timeout="1000">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-row>
        <v-progress-circular
          v-if="loader"
          indeterminate
          size="30"
          width="3"
          color="#3F4775"
          class="myloader"
        ></v-progress-circular>
      </v-container>
    </div>
  </div>
</template>

<script>
import config from "../../assets/configuration/config.js";
import axios from "axios";
import moment from "moment";
import download from "downloadjs";
export default {
  data() {
    return {
      ViewUser: [],
      selectedSummons:[],
      snackbarText: "Data Exported Successfully!.",
      snackbarColor: "green",
      Viewdialog: false,
      filteredText: "",
      pagination: {},
      selectedPageSize: 25,
      page: 1,
      optionspaging: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      SelectsummonStatus: 60,
      options: [
        { text: "All", value: 60 },
        { text: "Acknowledged", value: 20 },
        { text: "Unacknowledged", value: 30 },
        { text: "Completed", value: 50 },
        { text: "Refused", value: 40 },
      ],
      deleterecordName: "",
      snackbar: false,
      text: "Summon Deleted Successfully",
      timeout: 2000,
      loader: false,
      CurrentRecordArr: null,
      Deletedialog: false,
      PendingRecords: [],
      SummonObj: {
        pageSize: 25,
        page: 1,
        filterText: "",
        summonStatus: 50,
      },
      selectedRecord: null,
      defSelectedPage: 10,
      Pages: [10, 25, 50, "All"],
      selectedPage: 1,
      TotalPages: 10,
      AllRecrodsObj: {
        summonId: 0,
        caseId: "",
        caseTitle: "",
        court: "",
        name: "",
        address: "",
        issueDate: "",
        appearanceDate: "",
        contactNo: "",
        cnic: "",
        assignedTo: "",
        status: 10,
        subject: "",
        remarks: "",
        images: "",
        latitude: "",
        longitude: "",
      },

      headers: [

        {
          text: "Case Id",
          align: "start",
          sortable: false,
          width: "10%",
          value: "caseId",
        },
        {
          text: "Title",
          value: "caseTitle",
          align: "start",
          sortable: false,
          width: "10%",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          width: "10%",
          value: "name",
        },
        {
          text: "Court",
          value: "court",
          align: "start",
          sortable: false,
          width: "23%",
        },

        {
          text: "Issue Date",
          value: "issueDate",
          align: "start",
          sortable: false,
          width: "16%",
        },
        {
          text: "Appearance",
          value: "appearanceDate",
          align: "start",
          sortable: false,
          width: "16%",
        },

        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          width: "5%",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    navigateToView(item) {
      // console.log("item to details ---> ", item);
      
      this.$router.push({
        name: "addsummon",
        params: { id: item },
        query: { component: "completed" },
      });
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "";
    },
    GetAllSummon() {
      this.loader = true;
      axios
        .post(config.BaseUrl + "/api/Summon/GetAll", {
          pageSize: this.selectedPageSize,
          page: this.page,
          filterText: this.filteredText,
          summonStatus: this.SelectsummonStatus,
          userId: localStorage.getItem("LoggedInUser"),
          role: localStorage.getItem("role"),
        })
        .then((res) => {
        
          this.loader = false;
          this.PendingRecords = res.data.data;
          this.pagination = res.data.pagination;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    forwardButtonCall() {
      if (this.page < this.pagination.totalPages) {
        this.page += 1;
        this.GetAllSummon();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.page > 1) {
        this.page -= 1;
        this.GetAllSummon();
      } else {
        return;
      }
    },
    CurrentRecord(item) {
      console.log("Current Entity is " + item);
      this.CurrentRecordArr = item;
      this.deleterecordName = item.name;
    },
    async ViewRecord(item) {
      this.ViewUser = [];
     
      let currentId = item.summonId;
      
      try {
        let fd = new FormData();
        fd.append("summonId", currentId);
        let response = await axios({
          method: "Post",
          url: config.BaseUrl + "/api/Summon/Get",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        this.ViewUser = response.data.data;
      
      } catch (e) {
        console.log(e);
      }
    },

    async DeleteRecord() {
      this.loader = true;
      try {
        let fd = new FormData();
        fd.append("summonId", this.CurrentRecordArr.summonId);
        let response = await axios({
          method: "Post",
          url: config.BaseUrl + "/api/Summon/Remove",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        console.log(response);
        this.GetAllSummon();
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }
    },

    PendingRecord(item) {
      this.selectedRecord = item;
      localStorage.setItem("selectedPendingRecord", item);
    },
    clearlocalStorage() {
      localStorage.removeItem("selectedPendingRecord");
    },
    /*async downloadImage(url, summon) {

      const imageUrl = url;

      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        download(blob, 'downloaded_image.jpg', response.headers.get('content-type'));
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    },*/
    async ExportSummons() {
      try {
        const ids = this.selectedSummons.map(obj => obj.summonId);
        this.selectedSummons.forEach(summon =>{
          console.log(summon.images)
          if(summon.images !== null){
            let imagArray = summon.images.split(',')
            imagArray.forEach(img =>{
              download(img)
            })
          }
        });
        this.loader = true;
        let response = await axios.post(config.BaseUrl + '/api/Summon/Export', ids);
        var sampleArr = this.base64ToArrayBuffer(response.data.data);
        this.saveByteArray("Summons Report.xlsx", sampleArr);
        this.text = "Data Exported Successfully!.";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.loader = false;
      } catch (e) {
        this.text = "Something went wrong!";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.loader = false;
        this.loader = false;
        console.log(e)
      }
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      var blob = new Blob([byte], {type: "application/xlsx"});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    },
  },
  created() {
    this.clearlocalStorage();
    this.GetAllSummon();
  },
};
</script>
        