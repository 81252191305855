import Vue from 'vue'
import Vuex from 'vuex'

// import axios from "axios";

Vue.use(Vuex)
// var token = localStorage.getItem("token")
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
export default new Vuex.Store({
  state: {
    loggedIn: false,
    selectedPendingRecord: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
