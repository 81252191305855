<template>
  <div>
    <div>
      <v-card elevation="0" class="mySheet">
        <v-card-text color="" class="myCardText pa-0 py-1 px-6 ma-0">
          <v-layout class="marginLayout">
            <v-btn
              class="ThemeColor"
              dark
              elevation="0"
              height="35"
              link
              depressed
              to="/completed"
              tile
            >
              <v-icon small> ri-arrow-go-back-line </v-icon>
            </v-btn>

            <div>
              <h2 class="text-uppercase mx-3">
                Details of Summon / Notice / Warrant
              </h2>
            </div>
          </v-layout>
        </v-card-text>

        <v-container class="pa-3">
          <v-row class="">
            <v-col cols="12" md="8" class="pa-8">
              <v-row class="px-3">
                <v-col cols="12" md="4" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    v-model="user.fullName"
                    label="Balaf"
                    color="#3F4775"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4" class="pa-0">
                  <v-text-field
                    outlined
                    tile
                    dense
                    disabled
                    v-model="SummonModel.caseTitle"
                    label="Case Title"
                    color="#3F4775"
                  ></v-text-field
                ></v-col>

                <v-col cols="12" md="4" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    v-model="SummonModel.name"
                    label="Name"
                    color="#3F4775"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    disabled
                    clearable
                    outlined
                    dense
                    label="Issue Date"
                    readonly
                   :value="getFormattedDate(SummonModel.issueDate)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    disabled
                    dense
                   :value="getFormattedDate(SummonModel.appearanceDate)"
                    color="#3F4775"
                    label="Apearance Date"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="px-3">
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    disabled
                    v-model="SummonModel.subject"
                    outlined
                    dense
                    label="Subject"
                    color="#3F4775"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    outlined
                    disabled
                    dense
                    label="Remarks"
                    v-model="SummonModel.remarks"
                    color="#3F4775"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="px-3">
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    outlined
                    disabled
                    dense
                    label="Contact"
                    v-model="SummonModel.contactNo"
                    color="#3F4775"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    disabled
                    outlined
                    dense
                    label="CNIC No"
                    v-model="SummonModel.cnic"
                    return-masked-value
                    v-mask="'#####-#######-#'"
                    placeholder="XXXXX-XXXXXXX-X"
                    color="#3F4775"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Court"
                    v-model="SummonModel.court"
                    disabled
                    color="#3F4775"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <a
                    class="custom-anchor"
                    :href="getGoogleMapsUrl(latitude, longitude)"
                    target="_blank"
                    >Open Google Maps</a
                  >
                </v-col>
              </v-row>

              <v-row class="px-3">
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Judge"
                    :value="SummonModel.judge"
                    color="#3F4775"
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="SummonModel.status"
                    outlined
                    dense
                    label="Status"
                    color="#3F4775"
                    :items="statusItem"
                    disabled
                    item-value="value"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="pa-0 myCardText">
              <v-row>
                <v-col
                  cols="12"
                  v-for="(img, i) in images"
                  :key="i"
                  class="image-item"
                >
                  <v-img height="200" :src="img"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueMask from "vue-the-mask";
Vue.use(VueMask);
import config from "../../assets/configuration/config.js";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      statusItem: [
        { text: "Pending", value: 10 },
        { text: "Acknowledgeed", value: 20 },
        { text: "Unacknowledgeed", value: 30 },
        { text: "Refused", value: 40 },
        { text: "Completed", value: 50 },
      ],
      images: [],
      SummonModel: [],
      allCourts: [],
      JudgesList: [],
      user: [],
      address: "",
      latitude: 0,
      longitude: 0,
    };
  },
  methods: {
    getGoogleMapsUrl(latitude, longitude) {
      // console.log("these are the values",latitude,longitude)
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    },

    formatDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    async GetJudges() {
      try {
        this.filter.courtId = this.court.courtId;
        this.AddSummonObj.court = this.court.title;
        let response = await axios({
          method: "post",
          url: config.BaseUrl + "/api/Judge/GetAll",
          data: this.filter,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        this.JudgesList = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    GetSummon() {
      // console.log(summon_id)
      this.summonId = this.$route.params.id.summonId;
      var formData = new FormData();
      formData.append("summonId", this.summonId);
      axios
        .post(config.BaseUrl + "/api/Summon/Get", formData)
        .then((res) => {
          this.SummonModel = res.data.data;
          this.images = this.SummonModel.images.split(",").filter(item => item.trim() !== "");
          this.user = res.data.data.user;
          (this.latitude = this.SummonModel.latitude),
            (this.longitude = this.SummonModel.longitude);
        })
        .catch((Error) => {
          console.log(Error);
        });
    },
    getFormattedDate(date){
      return moment(date).format("DD MMM YYYY");
    }
  },
  computed: {
    formattedDateAppear() {
      // Format the date using Moment.js
      return moment(this.SummonModel.AppearanceDate).format("DD MMM YYYY"); // Replace the format with your desired format
    },
    formattedDateIssue() {
      // Format the date using Moment.js
      return moment(this.SummonModel.IssueDate).format("DD MMM YYYY"); // Replace the format with your desired format
    },
  },
  created() {
    this.GetSummon();
  },
};
</script>

<style>
.v-application--is-ltr .v-text-field .v-label {
  transform-origin: top left !important;
}
.v-text-field > .v-input__control > .v-input__slot .v-text-field__slot {
  outline: none !important;

  border: none !important;
}
.v-application .primary--text {
  color: none !important;
  caret-color: none !important;
}
.custom-anchor {
  display: block;
  width: 100%;
  padding: 7px;
  border: 1px solid #e4e4e4;
  text-align: center;
  text-decoration: none;
  color: blue;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 16px; /* Space between items */
}
</style>
